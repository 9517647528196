const appRoutes = [
    {
        path: '/auth',
        redirect: '/auth/signin',
        component: () => import('@/views/app/AuthIndexPage'),
        children: [
            {
                path: 'signin',
                name: 'SignInPage',
                component: () => import(/* webpackChunkName: "aaa--SignIn" */'@/components/app/auth/SignInForm'),
                meta: {
                    title: 'Sign In',
                    isPublic: true,
                },
            },
            {
                path: 'signup',
                name: 'SignUpPage',
                component: () => import(/* webpackChunkName: "aaa--SignUp" */'@/components/app/auth/SignUpForm'),
                meta: {
                    title: 'Sign Up',
                    isPublic: true,
                },
            },
            {
                path: 'password/reset',
                name: 'ResetPasswordPage',
                component: () => import(/* webpackChunkName: "aaa--ResetPassword" */'@/components/app/auth/ResetPasswordForm'),
                meta: {
                    title: 'Password Recovery',
                },
            },
            {
                path: 'password/new/:token',
                name: 'NewPasswordPage',
                component: () => import(/* webpackChunkName: "aaa--NewPassword" */'@/components/app/auth/NewPasswordForm'),
                meta: {
                    title: 'Change Password',
                    isPublic: true,
                },
            },
            {
                path: 'confirm/:token',
                name: 'UserVerifyAccount',
                component: () => import(/* webpackChunkName: "aaa--VerifyUser" */'@/components/app/auth/VerifyUserAccount'),
                meta: {
                    title: 'Confirm Email',
                },
            },
        ]
    },
    {
        path: '/',
        name: 'DashboardPage',
        redirect: '/auto-promotion',
        component: () => import('@/views/app/DashboardPage.vue'),
        meta: {
            isPrivate: true
        },
        children: [
            {
                path: 'auto-promotion',
                name: 'AutoPromotionPage',
                component: () => import('@/views/app/products/AutopromotionPage'),
                meta: {
                    isPrivate: true,
                    paymentRequired: true,
                    title: 'Auto Promotion',
                    product: 'autopromotion',
                    enabledTour: true
                }
            },
            {
                path: 'auto-boost',
                name: 'AutoBoostPage',
                component: () => import('@/views/app/products/AutoBoostPage.vue'),
                meta: {
                    isPrivate: true,
                    paymentRequired: false,
                    title: 'Auto Boost',
                    product: 'autoboost',
                },
                children: [
                    {
                        path: '/',
                        name: 'AutoBoostContainer',
                        component: () => import('@/components/app/products/autoboost/autoboost.container.vue'),
                        meta: {
                            isPrivate: true,
                            paymentRequired: false,
                            title: 'Auto Boost',
                            product: 'autoboost',
                            enabledTour: true
                        },
                    },
                    {
                        path: 'orders',
                        component: () => import('@/components/app/products/autoboost/components/orders/autoboost-orders-page.vue'),
                        meta: {
                            isPrivate: true,
                            paymentRequired: false,
                            product: 'autoboost',
                            enabledTour: false
                        },
                        children: [
                            {
                                path: ':page?',
                                name: 'AutoBoostPageOrders',
                                component: () => import('@/components/app/products/autoboost/components/orders/autoboost-orders.vue'),
                                meta: {
                                    isPrivate: true,
                                    paymentRequired: false,
                                    title: 'Auto Boost - Orders',
                                    product: 'autoboost',
                                },
                            },
                            {
                                path: 'order/:orderId',
                                name: 'AutoboostOrder',
                                component: () => import('@/components/app/products/autoboost/components/orders/autoboost-order.vue'),
                                meta: {
                                    isPrivate: true,
                                    paymentRequired: false,
                                    title: 'Auto Boost',
                                    product: 'autoboost',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'hashtag-generator',
                name: 'HashtagGeneratorPage',
                component: () => import('@/views/app/products/HashtagGeneratorPage'),
                meta: {
                    isPrivate: true,
                    paymentRequired: true,
                    title: 'Hashtag Generator',
                    product: 'hashtaggenerator',
                    enabledTour: true
                }
            },
            {
                path: 'comment-tracker',
                name: 'CommentTrackerPage',
                component: () => import('@/views/app/products/CommentTrackerPage'),
                meta: {
                    isPrivate: true,
                    paymentRequired: true,
                    title: 'Comment Tracker',
                    product: 'commenttracker',
                    enabledTour: true
                }
            },
            {
                path: 'schedule-posting',
                name: 'SchedulePostingPage',
                component: () => import('@/views/app/products/SchedulePostingPage'),
                meta: {
                    isPrivate: true,
                    paymentRequired: true,
                    title: 'Schedule Posting',
                    product: 'scheduleposting',
                    enabledTour: true
                }
            },
            {
                path: 'direct-messaging',
                name: 'DirectMessagesPage',
                component: () => import('@/views/app/products/DirectMessagesPage'),
                meta: {
                    isPrivate: true,
                    paymentRequired: true,
                    title: 'Direct Messaging',
                    product: 'directmessages',
                    enabledTour: true
                }
            },
            {
                path: 'influencer-discovery',
                name: 'InfluencerDiscoveryPage',
                component: () => import('@/views/app/products/InfluencersDiscoveryPage'),
                meta: {
                    isPrivate: true,
                    paymentRequired: false,
                    title: 'Influencers Discovery',
                    product: 'influencersdiscovery'
                },
                children: [
                    {
                        path: '/',
                        name: 'InfluencersDiscovery',
                        component: () => import('@/components/app/products/influencers-discovery/InfluencersDiscoveryIndex'),
                        meta: {
                            isPrivate: true,
                            paymentRequired: false,
                            title: 'Influencers Discovery',
                            product: 'influencersdiscovery',
                            enabledTour: true
                        },
                    },
                    {
                        path: 'report/:username',
                        name: 'InfluencerAccountDetails',
                        component: () => import('@/components/app/products/influencers-discovery/ProfileDetails'),
                        meta: {
                            isPrivate: true,
                            paymentRequired: false,
                            title: 'Influencer Profile Details',
                            product: 'influencersdiscovery',
                            enabledTour: false
                        },
                    },
                    {
                        path: 'list/:id',
                        name: 'InfluencersCustomList',
                        component: () => import('@/components/app/products/influencers-discovery/tabs/CustomListComponent'),
                        meta: {
                            isPrivate: true,
                            paymentRequired: false,
                            title: 'Influencers List',
                            product: 'influencersdiscovery',
                            enabledTour: false
                        },
                        // children: [
                        //     {
                        //         path: ':id',
                        //         name: 'InfluencersCustomList',
                        //         component: () => import('@/components/app/products/influencers-discovery/tabs/CustomListComponent'),
                        //         meta: {
                        //             isPrivate: true,
                        //             paymentRequired: false,
                        //             title: 'Influencers List',
                        //             product: 'influencersdiscovery'
                        //         },
                        //     }
                        // ]
                    },
                ]
            },
            {
                path: 'manage',
                name: 'ManagePage',
                component: () => import('@/views/app/ManagePage'),
                meta: {
                    title: 'Manage Accounts',
                    isPrivate: true,
                    paymentRequired: true,
                    product: false,
                    enabledTour: false
                }
            },
            {
                path: 'membership',
                name: 'MembershipPage',
                component: () => import('@/views/app/MembershipPage'),
                meta: {
                    title: 'Membership',
                    isPrivate: true,
                    product: false,
                    enabledTour: false
                }
            },
            {
                path: 'profile',
                name: 'ProfilePage',
                component: () => import('@/views/app/ProfilePage.vue'),
                meta: {
                    title: 'Profile',
                    isPrivate: true,
                    paymentRequired: false,
                    product: false,
                    enabledTour: false
                }
            },
        ],
    },
    {
        path: '*',
        name: 'NotFoundPage',
        component: () => import('@/views/NotFoundPage'),
        meta: { title: 'Page Not Found' }
    }
];

export default appRoutes;
