import Vue from 'vue';
import store from '@/store';
import router from '@/router';

import App from '@/App.vue';
import '@/global/styles.global';

import utilityGlobal from '@/global/utility.global';
import componentsGlobal from '@/global/components.global';
import defineAppPrototypes from '@/global/define-app-prototypes';

/**
 * Libs & Packages
 */
utilityGlobal(Vue, router);
componentsGlobal(Vue);
defineAppPrototypes(Vue);


new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');